<div class="mn bg-background-black py-32 md:py-60">
  <div class="px-16">
    <div class="wrapper">
      <div class="flex items-end justify-between w-full">
        <h2>Новини</h2>
        <a class="mn__all flex items-center gap-14 underline" [routerLink]="'/news'">
          <span>Всі новини</span>
          <mat-icon [svgIcon]="svgIcons.ARROW_BACK" />
        </a>
      </div>

      <div class="mt-30 md:mt-50 flex flex-col justify-between md:flex-row gap-44 md:gap-120 xl:gap-150">
        <div class="mn__main md:w-1/2">
          <app-main-news-card [card]="mainCard" [isMainNews]="true" />
        </div>
        <div class="mn__items md:w-1/2 gap-16 md:gap-30 flex flex-wrap">
          <div *ngFor="let card of cards" class="mn__item">
            <app-main-news-card [card]="card" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
