<div class="mnc relative" [class.mnc--main]="isMainNews">
  <div *ngIf="card.img && isMainNews" class="mnc__img relative">
    <div class="mnc__shadow hidden absolute top-0 left-0 w-full h-full"></div>
    <img [src]="card.img" [alt]="card.title" />
  </div>
  <div class="mnc__info" [class.mnc__info--main]="isMainNews">
    <div
      class="mnc__date mt-16 md:text-background-white opacity-50 text-10 md:text-12 px-8 py-3 bg-background-white md:bg-transparent text-background-black md:px-0 md:py-0 flex"
    >
      {{ card.date }}
    </div>
    <a [routerLink]="'/news/' + card.id" class="mnc__title mt-16">
      <h3 *ngIf="isMainNews; else default">
        {{ card.title }}
      </h3>
      <ng-template #default>
        <p>{{ card.title }}</p>
      </ng-template>
    </a>
  </div>
</div>
